import React from 'react'
import {
    nameRegex,
    phonenumberRegex,
    dobEarliest,
    dobLatest,
    validateDisplayName,
    validateEmail
} from '../services/validation';
import './userprofile.css'
import InputBlock from './elements/InputBlock'
import UserEmail from './UserEmail'
import {
    getUserDisplayName,
    getUserEmail,
    setUserDisplayName,
    setUserEmail,
    getUserPhoneNumber,
    appCheckTokenResponse,
    idTokenResponse
} from '../services/firebase/Firebase'
import { postUserProfile } from '../objects/User'

function UserProfile(props) {
  
    const handleSubmit = async (event) => {
        event.preventDefault()
        
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation()
        } else {
            const formData = form.elements

            const displayName = formData.form_displayname.value;
            if (displayName !== ""  && validateDisplayName(displayName)) {

                // if the display name is not already stored, then submit it
                if (displayName !== getUserDisplayName()) {
                    setUserDisplayName(displayName)
                    .catch((err) => {
                        console.log(err)
                    })
                }
            } else {
                // the display name is either empty of invalid.  Ignore it.
            }

            const email = formData.form_email.value;
            if (email !== ""  && validateEmail(email)) {
                if (email !== getUserEmail()) {
                    setUserEmail(email)
                    .then(result => {
                        // email set successfully
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                } else {
                    console.log("email exists")
                }
            } else {
                console.log("email not valid")
            }

            await postUserProfile(JSON.stringify({
                given_name: formData.form_firstname.value,
                middle_names: formData.form_middlenames.value,
                family_name: formData.form_familyname.value,
                date_of_birth: formData.form_dob.value
            })).then(result => {
                //console.log("The response: %o", result)
                props.onRegistered(true)
            })
            .catch(err => {
                console.log(`Could not post new user: ${err}`)
            })
        }
    }

    let content;

    const informalNameInput = [{
        name:"displayname",
        value:(getUserDisplayName()?getUserDisplayName():""),
        type:"text",
        placeholder:"optional",
        pattern:nameRegex
    }]
        
    const informalNameInputBlock = (
        <InputBlock
            title={"Display Name"}
            hint={"your nickname, or friendly name"}
            fields={informalNameInput}
        />
    )

    const formalNameInput = [{
        name:"firstname",
        type:"text",
        placeholder:"First name",
        pattern:nameRegex,
        required:true
    },
    {
        name:"middlenames",
        type:"text",
        placeholder:"Middle name(s)",
        pattern:nameRegex
    },
    {
        name:"familyname",
        type:"text",
        placeholder:"Family name",
        pattern:nameRegex,
        required:true
    }]

    const formalNameInputBlock = (
        <InputBlock
            title={"Legal Name"}
            hint={"as displayed in passport/nat. id card/drivers licence"}
            fields={formalNameInput}
        />
    )

    const dobInput = [{
        name:"dob",
        type:"date",
        min:dobEarliest(),
        max:dobLatest(),
        required:true
    }]
        
    const dobInputBlock = <InputBlock title={"Date of Birth"} fields={dobInput} />

    const mobileInput = [{
        name:"mobile",
        type:"tel",
        value:getUserPhoneNumber(),
        placeholder:"+441234567890",
        pattern:phonenumberRegex,
        disabled:true
    }]
        
    const mobileInputBlock = <InputBlock title={"Mobile"} fields={mobileInput} />

    content = (
        <form onSubmit={handleSubmit}>
            <div id="signupform">
                <div className="signupform_row" id="informalinfo">
                    {informalNameInputBlock}
                </div>
                <div className="signupform_row" id="formalinfo">
                    {formalNameInputBlock}
                </div>
                {/* In reverse order so that both mobile and dob wrap. */}
                <div className="signupform_row spaced_flexbox" id="contactinfo">
                    {dobInputBlock}
                    {mobileInputBlock}
                    <UserEmail standalone={false} currentEmail={getUserEmail()} />
                </div>
                
                <div className="signupform_row">
                    <button className="submit_button rounded_corners" type="submit">
                        Submit
                    </button>
                </div>
            </div>
        </form>
    )

    return (
        <>
            {content}
        </>
    )
}
  
export default UserProfile;