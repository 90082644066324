import React, { useState } from 'react'
import { emailRegex } from '../services/validation';
import InputBlock from './elements/InputBlock';

/**
 * 
 * @param {currentEmail, emailVerified, standalone} props 
 * @returns 
 */

function UserEmail(props) {
    // eslint-disable-next-line
    const [userHasEmail, setUserHasEmail] = useState((props.currentEmail)?true:false);
    // eslint-disable-next-line
    const [emailVerified, setEmailVerified] = useState((props.emailVerified)?(props.emailVerified):false);

    let content;

    const verifyButton = (props.standalone)?(
        <div className="content_default">
            <div className="message">An email has been sent to the given address.  If not received, please check junk first. If nothing has arrived by tomorrow, drop a message to Baz.</div>
        </div>
    ):""

    const emailInput = [{
        name:"email",
        type:"email",
        placeholder:"name@example.com",
        pattern:emailRegex,
        required:true
    }]

    if (userHasEmail) {
        emailInput[0].value = props.currentEmail;
        emailInput[0].disabled = true;
    }
        
    const emailInputBlock = (
        <>
            <InputBlock
                title={"Email"}
                fields={emailInput}
                // status= {{
                //     type: "green",
                //     message: "Unverified"
                // }}
            />
        </>
    )

    content = (
        <div id="email_input" className="centered_flexbox">
            {emailInputBlock}
            {verifyButton}
        </div>
    )

    return (
        <div id="useremail">
            {content}
        </div>
    )
}
  
export default UserEmail;