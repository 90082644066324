import configValues from '../AppInit';
import { appCheckTokenResponse, idTokenResponse } from '../services/firebase/Firebase';

export function getFlights() {
    return new Promise((resolve, reject) => {
        appCheckTokenResponse()  // request and AppCheck token
        .then((appCheckToken) => {  // appCheck token successfully returned
            idTokenResponse()
            .then(async (idToken) => { // userId token successfully returned
                const response = await fetch(configValues.apiUrl + "/readflights", {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        //  "Content-Type": "application/json",
                        'X-Firebase-AppCheck': appCheckToken,
                        'X-Firebase-UserId': idToken
                    }
                    //, body: JSON.stringify({ username: "example" })
                })
                await response.json()
                .then(data => resolve(data))  
                .catch(err => {
                    reject("Error making API call: " + err)
                })          
            })
            .catch((error) => {   // userId token failed to return
                reject("Failed to get User Id token.")
            })
        })
        .catch((error) => {   // appCheck token failed to return
            reject("Failed to get App Check token.")
        })
    })
  }