// eslint-disable-next-line
export const emailRegex = '[^\\s@]+@[^\\s@]+\\.[^\\s@]+'
// eslint-disable-next-line
export const nameRegex = '[A-Z][a-zA-Z \\-\'.]*'

export const phonenumberRegex = '\\+[1-9]\\d{1,14}'

export const validateEmail = (email) => {
  return email.match(new RegExp('^' + emailRegex + '$'))
}

export const validateDisplayName = (displayName) => {
  return displayName.match(new RegExp('^' + nameRegex + '$'))
}

export const validatePhonenumber = (phonenumber) => {
  return phonenumber.match(new RegExp('^' + phonenumberRegex + '$'))
}

export const dobEarliest = () => {
  const today = new Date()
  let dd = String(today.getDate()).padStart(2, 0)
  let mm = String(today.getMonth() + 1).padStart(2, 0)
  let yyyy = today.getFullYear() - 90;

  return `${yyyy}-${mm}-${dd}`
}

export const dobLatest = () => {
  const today = new Date()
  let dd = String(today.getDate()).padStart(2, 0)
  let mm = String(today.getMonth() + 1).padStart(2, 0)
  let yyyy = today.getFullYear() - 18;

  return `${yyyy}-${mm}-${dd}`
}