import React, { useState, useEffect } from 'react'
import configValues from '../AppInit';
import './flightpanel.css'
import FlightLeg from './elements/FlightLeg';
import NationalFlag from './elements/NationalFlag';
import { getFlights } from '../objects/Flight'
import AniMotif from './elements/AniMotif';

const FlightPanel = (props) => {
    const [result, setDataSet] = useState(null);

    let content = ""
    let flightbookings = []

    useEffect(() => {
        const fetchData = async () => {

            getFlights().then(result => {
                setDataSet(result)
            })
        }
    
        fetchData();
      }, []);
    

        if (result === null) {
            return (
            <div className="progressbar_box">
          <AniMotif width={'60%'} />
            </div>
            )
        }
                    
        result.flightbookings.map((flightbooking) => {
            let booking = []
            
            booking.push(
                <>
                    <div className="booking_header">
                        <NationalFlag countrycode={flightbooking.flights[0].origin_country} width={15+'%'}/>
                        <div className="booking_info"><b>{flightbooking.vendor_name}</b><br/>Ref: {flightbooking.vendor_reference}</div>
                        <NationalFlag countrycode={flightbooking.flights[flightbooking.flights.length-1].destination_country} width={15+'%'}/>
                    </div>
                    <div className="booking_header">
                        <div><b>{flightbooking.flights[0].origin_airport}</b></div>
                        <div><b>{flightbooking.flights[flightbooking.flights.length-1].destination_airport}</b></div>
                    </div>
                    <div className="booking_header">
                        <div>{new Date(flightbooking.flights[0].departure_time).toLocaleString('en-EU', configValues.flightDateFormat)}</div>
                        <div>{new Date(flightbooking.flights[flightbooking.flights.length-1].arrival_time).toLocaleString('en-EU', configValues.flightDateFormat)}</div>
                    </div>
                    <div className="booking_header">
                        <div>{new Date(flightbooking.flights[0].departure_time).toLocaleString('en-EU', configValues.flightTimeFormat)}</div>
                        <div>{new Date(flightbooking.flights[flightbooking.flights.length-1].arrival_time).toLocaleString('en-EU', configValues.flightTimeFormat)}</div>
                    </div>
                    <div className="email">Booking email: <b>{flightbooking.booking_email}</b></div>
                </>
            )

            if (flightbooking.flights.length > 1){
                flightbooking.flights.map((flight) => {
                
                    booking.push(
                        <>
                            <div className="flight_leg">
                                <div className="booking_info">Flight: <b>{flight.flight_number}</b></div>
                                <div className="booking_header">
                                    <div><b>{flight.origin_airport}</b></div>
                                    <div><b>{flight.destination_airport}</b></div>
                                </div>
                                <div className="booking_header">
                                    <div>{new Date(flight.departure_time).toLocaleString('en-EU', configValues.flightDateFormat)}</div>
                                    <div>{new Date(flight.arrival_time).toLocaleString('en-EU', configValues.flightDateFormat)}</div>
                                </div>
                                <div className="booking_header">
                                    <div>{new Date(flight.departure_time).toLocaleString('en-EU', configValues.flightTimeFormat)}</div>
                                    <div>{new Date(flight.arrival_time).toLocaleString('en-EU', configValues.flightTimeFormat)}</div>
                                </div>                            

                            </div>
                        </>
                    )

                })
            } else {
                
                booking.push(
                    <div className="flight_leg">
                        <div className="booking_info">Flight: <b>{flightbooking.flights[0].flight_number}</b></div>
                    </div>
                )

            }

            flightbookings.push(
                <>
                <div className="rounded_corners content_block flight_panel">
                    {booking}
                </div>
                </>
            )
        })
        content = (
            <>
                {flightbookings}
            </>
        )

    return (
        <>
                <div className="itin_title">Flight itinerary<br/><b>{result.passengerName}</b></div>
            {content}
        </>
    )
}
  
export default FlightPanel;