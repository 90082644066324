import { useContext, useState } from 'react'
import { AppContext } from '../../contexts/AppContext'
import { AuthenticationContext } from '../../contexts/AuthenticationContext'

import firebase from 'firebase/compat/app'
import { getAnalytics, logEvent } from "firebase/analytics"
import { initializeAppCheck, ReCaptchaEnterpriseProvider, getToken } from "firebase/app-check"

import { validateDisplayName, validateEmail } from '../validation'

import FBAuthView from './FBAuthView'
import UserProfile from '../../components/UserProfile'
import UserEmail from '../../components/UserEmail'
import AniMotif from '../../components/elements/AniMotif'

const firebaseConfig = require("./rdi-connect-firebase.json")

//E05F71AD-C9AB-4998-B38E-E57D4AAC871C

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = firebase.auth()
let currentUser = null

/*********** API GATEKEEPERS - begin ***********/

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
/* eslint-disable-next-line no-restricted-globals */
//self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider('6LfzphEqAAAAAAjpvAIbfzDyqzy_WbxOm_uMK6zg'),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});

export const appCheckTokenResponse = async () => {
  try {
      return (await getToken(appCheck, /* forceRefresh= */ true)).token
  } catch (err) {
      console.log("App Check Failed: " + err.code)
      // Handle any errors if the token was not retrieved.
      return;
  }
}

export const idTokenResponse = async () => {
  try {
      return await currentUser.getIdToken()
  } catch (err) {
      console.log("ID Check Failed: " + err.code)
      // Handle any errors if the token was not retrieved.
      return;
  }
}

/*********** API GATEKEEPERS - end ***********/


function currentUserExists() {
  return (currentUser !== null)
}

// exported setter functions
export function setUserEmail(newEmail) {
  return new Promise((resolve, reject) => {
    if(!currentUserExists()) reject("No current user.")
    if(!validateEmail(newEmail)) reject(`Email not valid: ${newEmail}`)

    currentUser.updateEmail(newEmail).then(updateResult => {
      currentUser.sendEmailVerification()
      .then(sendVerificationResult => {
        resolve(`Email updated: ${updateResult}\nVerification sent: ${sendVerificationResult}`)
      })
      .catch((err) => {
        reject(`Failed to send verification email. ${err.code}`)
      })
    }).catch((err) => {
      if (err.code === 'auth/requires-recent-login') {
        reject("Authorisation expired.")
      }
      reject(`Failed to update email. ${err.code}`)
    });
  })
}

export function setUserDisplayName(newDisplayName) {
  return new Promise((resolve, reject) => {
    if(!currentUserExists()) reject("No current user.")
    if(!validateDisplayName(newDisplayName)) reject(`Display name not valid: ${newDisplayName}`)

    currentUser.updateProfile({
      displayName: newDisplayName
    }).then(result => {
      resolve(`Display name updated: ${result}`)
    })
    .catch((err) => {
      if (err.code === 'auth/requires-recent-login') {
        reject("Authorisation expired.")
      }
      reject(`Failed to set display name. ${err.code}`)
    });
  })
}

export function getUserDisplayName() {
  return currentUserExists()?((currentUser.displayName)?(currentUser.displayName):false):false
}

export function getUserEmail() {
  return currentUserExists()?((currentUser.email)?(currentUser.email):false):false
}

export function getUserPhoneNumber() {
  return currentUserExists()?((currentUser.phoneNumber)?(currentUser.phoneNumber):false):false
}

export function removeAuth() {
  auth.signOut()
}

function Firebase() {
    const {isLoggedIn, setLoggedIn} = useContext(AppContext)
    
    const [isAuthenticated, setAuthenticated] = useState(null)
    const [isRegistered, setRegistered] = useState(null)
    const [isVerified, setVerified] = useState(null)
    
    let content;

    auth.onAuthStateChanged((user) => {
      console.log(`User state: `
        + `\n Authenticated: ${isAuthenticated}`
        + `\n Registered: ${isRegistered}`
        + `\n Verified: ${isVerified}`
        + `\n Logged In: ${isLoggedIn}`)  // TODO: remove from production version

      if (user) {
        currentUser = user
        if (isLoggedIn === null) setLoggedIn(false)

          // COMMENTED OUT TO REMOVE EMAIL REQUIREMENT - TEMP

        setAuthenticated(true)
        //if (user.email !== null) {
          setRegistered(true)
          //if (user.emailVerified) {
            setVerified(true)
            setLoggedIn(true)
          //} else {
          //  setVerified(false)
          //}
        //} else {
        //  setRegistered(false)
        //}

        logEvent(analytics, `Auth state: User ${user.uid} Authenticated.`)
      } else {
        currentUser = null
        setLoggedIn(false)
        setVerified(false)
        setRegistered(false)
        setAuthenticated(false)
      }
    });

    if (isLoggedIn !== null) {
      if (isAuthenticated) {
        if (isRegistered) {
            content = (
              <div className="default_content">
                <UserEmail standalone={true} currentEmail={currentUser.email}/>
              </div>
            )
            // do verification
        } else {
            content = (
              <AuthenticationContext.Provider value={{isRegistered, setRegistered}}>
                <UserProfile onRegistered={setRegistered}/>
              </AuthenticationContext.Provider>
            )
        }
      } else {
          content = <FBAuthView auth={auth}/>
      }
    } else {
      content = <AniMotif width={'60%'} />
    }

    return (
      <>
        {content}
      </>
    )
}
  
export default Firebase