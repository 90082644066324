import  { useEffect } from 'react'
import './fbauthview.css'
import firebase from 'firebase/compat/app'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

/*
    FirebaseUI Authentication.
*/

const FBAuthView = (props) => {
    const auth = props.auth

    useEffect(() => {
        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
        ui.start(".firebase-auth-container", {

            callbacks: {
                'signInSuccessWithAuthResult': function(authResult, redirectUrl) {
                    if (authResult.user) {
                        // do something
                    }
                    return false; // don't redirect
                }
            },
            signInOptions: [
                // {
                //     provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                //     signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
                // },
                {
                    provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                    defaultCountry: 'GB'
                }
            ]
        });
    }, [auth]);

    return (
        <div className={"firebase-auth-container"}></div>
    );
};

export default FBAuthView