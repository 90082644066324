import React from 'react'
import './inputblock.css'

function InputBlock(props) {

    let content
    
    content = (
        <>
            <div className="meta_div">
            <span>{props.title}</span>
            {(props.hint)?(<small className="hint_text">({props.hint})</small>):""}
            </div>

            {props.fields.map((item) => {
                return (
                    <input      
                        type={item.type}
                        name={item.name}
                        defaultValue={item.value}
                        id={"form_" + item.name}
                        placeholder={item.placeholder}
                        pattern={item.pattern}
                        required={item.required}
                        disabled={item.disabled}
                        min={item.min}
                        max={item.max}
                        key={"key_" + item.name}
                    />
                );
            })}
        </>
    )

    let statusBar = "";
    
    if (props.status) {
        statusBar =  <div className={"rounded_corners status_bar status_" + props.status.type} id={props.title + "_status"}>{props.status.message}</div>
    }

    return (
        <>
            <div className="inputblock rounded_corners">
                {content}
            </div>
            {statusBar}
        </>
    )
}
  
export default InputBlock;