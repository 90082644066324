import './animotif.css'

function AniMotif(props) {

    console.log("AniMotif rendered")

    return (
        <svg style={{width:props.width}} viewBox="0 0 300 171">
            <g>
                <path className="flash" id="flash1" d="M0,172L66,58h57L57,172H0z"/>
                <path className="flash" id="flash2" d="M72,172l82-142.5h57L129,172H72z"/>
                <path className="flash" id="flash3" d="M144,172L243,1h57l-99,171H144z"/>
            </g>
        </svg>
    )
}
  
export default AniMotif;