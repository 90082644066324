import React from 'react'

/** Usage: <NationalFlag countrycode="ES"/> */

function NationalFlag(props) {

    // Flag specs
    const AT = { // Austria
        type: "bands",
        direction: "horizontal",
        colors: {
            band1: "#C8202F",
            band2: "#FFFFFF",
            band3: "#C8202F"
        },
        emblem: "eagle"
    }
    const AZ = { // Azerbaijan
        type: "bands",
        direction: "horizontal",
        colors: {
            band1: "#00B5E2",
            band2: "#EF3340",
            band3: "#509E2F"
        },
        emblem: "Moon and star"
    }
    const BE = { // Belgium
        type: "bands",
        direction: "vertical",
        colors: {
            band1: "#000000",
            band2: "#FDDA24",
            band3: "#EF3340"
        }
    }
    const BG = { // Bulgaria
        type: "bands",
        direction: "horizontal",
        colors: {
            band1: "#FFFFFF",
            band2: "#009900",
            band3: "#CC0000"
        }
    }
    const HR = { // Croatia
        type: "bands",
        direction: "horizontal",
        colors: {
            band1: "#FF0000",
            band2: "#FFFFFF",
            band3: "#171796"
        },
        emblem: "Shield"
    }
    const CZ = { // Czechia
        type: "triangle",
        triangleColor: "#11457E",
        direction: "horizontal",
        colors: {
            band1: "#FFFFFF",
            band2: "#D7141A"
        }
    }
    const DK = { // Denmark
        type: "offset_cross",
        offset: 1/2,
        crossThickness: 1/7,
        colors: {
            background: "#C8102E",
            cross: "#FFFFFF"
        }
    }
    const FI = { // Finland
        type: "offset_cross",
        offset: 13/22,
        crossThickness: 3/11,
        colors: {
            background: "#FFFFFF",
            cross: "#002F6C"
        }
    }
    const GE = { // Georgia
    }
    const DE = { // Germany
        type: "bands",
        direction: "horizontal",
        colors: {
            band1: "#000000",
            band2: "#FF0000",
            band3: "#FFCC00"
        }
    }
    const FR = { // France
        type: "bands",
        direction: "vertical",
        colors: {
            band1: "#0055A4",
            band2: "#FFFFFF",
            band3: "#EF4135"
        }
    }
    const GR = { // Greece
    }
    const HU = { // Hungary
        type: "bands",
        direction: "vertical",
        colors: {
            band1: "#0055A4",
            band2: "#FFFFFF",
            band3: "#EF4135"
        }
    }
    const IS = { // Iceland
        type: "offset_cross",
        offset: 1/2,
        crossThickness: 1/9,
        strokeCrossThickness: 2/9,
        colors: {
            background: "#02529C",
            cross: "#DC1E35",
            stroke: "#FFFFFF"
        }
    }
    const IE = { // Ireland
        type: "bands",
        direction: "vertical",
        colors: {
            band1: "#169B62",
            band2: "#FFFFFF",
            band3: "#FF883E"
        }
    }
    const IT = { // Italy
        type: "bands",
        direction: "vertical",
        colors: {
            band1: "#008C45",
            band2: "#F4F5F0",
            band3: "#CD212A"
        }
    }
    const LV = { // Latvia - unsual stripe
    }
    const LT = { // Lithuania
        type: "bands",
        direction: "horizontal",
        colors: {
            band1: "#FDB913",
            band2: "#006A44",
            band3: "#C1272D"
        }
    }
    const LU = { // Luxembourg
        type: "bands",
        direction: "horizontal",
        colors: {
            band1: "#EF3340",
            band2: "#FFFFFF",
            band3: "#00A3E0"
        }
    }
    const MT = { // Malta
        type: "bands",
        direction: "vertical",
        colors: {
            band2: "#FFFFFF",
            band3: "#CF142B"
        },
        emblem: "cross"
    }
    const MC = { // Monaco
        type: "bands",
        direction: "horizontal",
        colors: {
            band2: "#DC143C",
            band3: "#FFFFFF"
        }
    }
    const NL = { // Netherlands
        type: "bands",
        direction: "horizontal",
        colors: {
            band1: "#AD1D25",
            band2: "#FFFFFF",
            band3: "#1E4785"
        }
    }
    const NO = { // Norway
        type: "offset_cross",
        offset: 1/2,
        crossThickness: 1/8,
        strokeCrossThickness: 1/4,
        colors: {
            background: "#BA0C2F",
            cross: "#00205B",
            stroke: "#FFFFFF"
        }
    }
    const PL = { // Poland
        type: "bands",
        direction: "horizontal",
        colors: {
            band1: "#FFFFFF",
            band2: "#DC143C"
        }
    }
    const PT = { // Portugal - unusual bands
    }
    const RO = { // Romania
        type: "bands",
        direction: "vertical",
        colors: {
            band1: "#002B7F",
            band2: "#FCD116",
            band3: "#CE1126"
        }
    }
    const SM = { // San Marino
        type: "bands",
        direction: "horizontal",
        colors: {
            band1: "#FFFFFF",
            band2: "#59A7D3"
        }
    }
    const RS = { // Serbia
        type: "bands",
        direction: "horizontal",
        colors: {
            band1: "#C7363D",
            band2: "#0C4077",
            band3: "#FFFFFF"
        }
    }
    const SK = { // Slovakia
        type: "bands",
        direction: "horizontal",
        colors: {
            band1: "#FFFFFF",
            band2: "#0B4EA2",
            band3: "#EE1C25"
        },
        emblem: "Shield"
    }
    const SI = { // Slovenia
        type: "bands",
        direction: "horizontal",
        colors: {
            band1: "#FFFFFF",
            band2: "#0000FF",
            band3: "#FF0000"
        },
        emblem: "Shield"
    }
    const ES = { // Spain
        type: "bands",
        direction: "horizontal",
        colors: {
            band1: "#AD1519",
            band2: "#FABD00",
            band3: "#FABD00",
            band4: "#AD1519"
        },
        emblem: "Shield"
    }
    const LK = { // Sri Lanka
    }
    const SE = { // Sweden
        type: "offset_cross",
        offset: 3/5,
        crossThickness: 1/5,
        colors: {
            background: "#006AA7",
            cross: "#FFCD00"
        }
    }
    const CH = { // Switzerland
    }
    const GB = {  // United Kingdom
        type: "union",
        colors: {
            red: "#C8102E",
            white: "#FFFFFF",
            blue: "#012169"
        }
    }

    const subject = eval(props.countrycode)
    const unit = 66
    const flagWidthProportion = 3
    const flagHeightProportion = 2

    let content
    let sections = []
    let flagWidth = unit*flagWidthProportion
    let flagHeight = unit*flagHeightProportion
    let shiftX = 0
    let shiftY = 0
    let bandWidth, bandHeight

    switch(subject.type) {
        case "offset_cross":
            sections.push(<rect x="0" y="0" fill={subject.colors.background} width={flagWidth} height={flagHeight} key="bg" />)
            let cross = "M".concat((flagHeight*subject.offset), ",0 v", flagHeight, " M0,", flagHeight/2, " h", flagWidth)
            if (subject.colors.stroke !== null)
                sections.push(<path d={cross} stroke={subject.colors.stroke} strokeWidth={flagHeight*subject.strokeCrossThickness} key="stroke" />)
            sections.push(<path d={cross} stroke={subject.colors.cross} strokeWidth={flagHeight*subject.crossThickness} key="cross" />)
            break;
        case "bands":
        case "triangle":
            switch (subject.direction) {
                case "vertical":
                    bandWidth = flagWidth/Object.keys(subject.colors).length
                    bandHeight = flagHeight
                    shiftX = bandWidth
                    break
                case "horizontal":
                    bandWidth = flagWidth
                    bandHeight = flagHeight/Object.keys(subject.colors).length
                    shiftY = bandHeight
            }
            let index = 0
            for (const colorband in subject.colors) {
                sections.push(<rect x={index*shiftX} y={index*shiftY} fill={subject.colors[colorband]} width={bandWidth} height={bandHeight} key={index} />)
                index++;
            }

            if (subject.type === "triangle") {
                let path = "M ".concat(flagWidth/2, ",", flagHeight/2, " 0,0 V ", flagHeight, " z")
                sections.push(<path d={path} fill={subject.triangleColor}  key="triangle" />)
            }
            break;
        case "union":
            sections.push(<rect x="0" y="0" fill={subject.colors.blue} width={flagWidth} height={flagHeight} key="bg" />)
            let whiteScotlandCross = "M0,0 L".concat(flagWidth, ",", flagHeight, " M", flagWidth,",0 L0,", flagHeight, "")
            sections.push(<path d={whiteScotlandCross} stroke={subject.colors.white} strokeWidth={flagWidth/10} key="wsc" />)
            let clipPath = "M ".concat(flagWidth/2, ",", flagHeight/2, " h", flagWidth/2, " v", flagHeight/2,
                " z ", " v", flagHeight/2, " h-", flagWidth/2,
                " z ", " h-", flagWidth/2, " v-", flagHeight/2,
                " z ", " v-", flagHeight/2, " h", flagWidth/2, " z")
            sections.push(<clipPath id="clipPath" key="cp"><path d={clipPath} /></clipPath>)
            let redCross = "M0,0 L".concat(flagWidth, ",", flagHeight, " M", flagWidth,",0 L0,", flagHeight, "")
            sections.push(<path d={redCross} stroke={subject.colors.red} clipPath="url(#clipPath)" strokeWidth={flagWidth/12} key="rc" />)
            let whiteCross = "M".concat(flagWidth/2, ",0 v", flagHeight, " M0,", flagHeight/2, " h", flagWidth)
            sections.push(<path d={whiteCross} stroke={subject.colors.white} strokeWidth={flagWidth/6} key="wc" />)
            let redEnglandCross = "M".concat(flagWidth/2, ",0 v", flagHeight, " M0,", flagHeight/2, " h", flagWidth)
            sections.push(<path d={redEnglandCross} stroke={subject.colors.red} strokeWidth={flagWidth/10} key="rec" />)
    }
    
    content = (       
        <svg
            className=""
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 198 132" width={props.width}>
                {sections}
        </svg>
    )

    return (
        <>
            {content}
        </>
    )
}
  
export default NationalFlag;